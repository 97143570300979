.reactMarkDown {
  background-color: white;
  color: #8999a5;
}

.reactMarkDown h1 {
  font-size: 2rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

.reactMarkDown h2 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

.reactMarkDown h3 {
  font-weight: bold;
}

.reactMarkDown ol {
  margin-bottom: 1rem;
  padding-inline-start: 1rem;
}

.reactMarkDown p {
  margin-bottom: 1rem;
}

.reactMarkDown ul {
  padding-inline-start: 1rem;
  margin-bottom: 1rem;
}

.reactMarkDown ul li:before {
  content: '–';
  display: inline-block;
  color: #888;
  position: relative;
  margin-left: -0.9375em;
  margin-right: 5px;
}

.rc-md-editor .editor-container .sec-html .html-wrap {
  height: 100%;
  box-sizing: border-box;
  overflow: auto;
  position: relative;
}
