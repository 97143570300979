@page {
  margin: 0;
}

.print {
  width: 264px;
  margin: auto;
  /* font-size: 0.55rem; */
  font-family: Arial, monospace;
}

.footer-detail {
  display: flex;
  justify-content: space-between;
  padding: 0.2rem 0.4rem;
}

.item-list {
  display: flex;
  justify-content: space-between;
  padding: 0.2rem;
}

@media print and (min-width: 320px) {
  .print {
    font-family: Arial, monospace;
    margin: 0;
    font-size: 0.6rem;
  }
}

@media print {
  .print {
    width: 264px;
    font-family: 'Arial', monospace;
    margin: auto;
    font-weight: 500;
    font-size: 0.6rem;
    color-adjust: exact !important;
    text-size-adjust: none;
    -webkit-text-size-adjust: none;
    -moz-text-size-adjust: none;
    -ms-text-size-adjust: none;
  }

  .hidden-print,
  .hidden-print * {
    display: none !important;
  }
}
