@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .max-h-product {
    max-height: calc(100vh - 195px);
  }

  .footer-pagination {
    background: white;
    bottom: 0;
    left: 0;
    width: 100%;
    box-shadow: 0px -4px 6px -1px rgba(23, 80, 22, 0.06);
  }

  .divider {
    @apply h-[2px] w-full bg-gray-100;
  }
}

@layer utilities {
  .loading {
    display: inline-block;
    clip-path: inset(0 0.9ch 0 0);
    animation: l 1s steps(4) infinite;
  }

  @keyframes l {
    to {
      clip-path: inset(0 -1ch 0 0);
    }
  }
}

.fc_custom {
  transform-origin: 0% 50% !important;
}
